import React from "react";
import SliderMe from "../components/SliderMe";
import ShareIcons from "../components/ShareIcons";
import { Link } from "react-router-dom";

const Inner18 = () => {
  return (
    <>
      <section className="CaseInnerWrapper">
        <div className="CaseInnerContainer">
          <section className="InnerHero">
            <img
              src="/assets/18new-2a97f445.png"
              alt="Comprehensive Engineering Solutions To "
              style={{ width: "100%" }}
            />
            <h2 className="InnerHeroH1 font-face-futur-md-bt">
              Comprehensive Engineering Solutions To&nbsp;{" "}
              <span className="orange">Power Plant Excellence.</span>{" "}
            </h2>
            <div className="tagsD font-face-futur-HV" id="topGap">
            <Link to="/sectors/power" className="tags2">Power</Link>
            <Link to="/services/owners-engineering" className="tags2">Owner’s Engineering</Link> 
            </div>
            <div className="bar">
              <h2 className="InnerHeroH2 font-face-futur-md-bt">
                <div className="lineabc" />
                The Problem at Hand
              </h2>
              <p className="font-face-futur-BK innerPara">
                Optimizing the performance and efficiency of Solar PV Plants,
                addressing challenges from planning to commissioning, to ensure
                sustainable and reliable energy generation.
              </p>
            </div>
            <div className="why font-face-futur-md-bt">
              <h1>
                Why <span>SIXD?</span>
              </h1>
              <p className="whyPara font-face-futur-LT">
                SIXD stands as the preferred partner for Solar PV Plants,
                offering a holistic approach from planning to execution. With
                expertise in feasibility assessments, bid management, and
                project execution, SIXD ensures tailored solutions for solar
                energy projects.
              </p>
            </div>
            <div className="section-inner">
              <div className="lineabc2" />
              <h1 className="section-heading-inner font-face-futur-BK">
                Scope of Work
              </h1>
              <div className="card-container-inner">
                <p className="font-face-futur-LT" />
                <div id="topGap2">
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMLSURBVHgB7VixchoxEF2IZ2xXuRkXLnOd7SpXpgufAF06yBfE/gJIly7kCwxfYPIFvpTpSMEAlZUuDTPnCiiAvCWSI4QEJw7GLu7NaKTTaVdv91bS6ohy5MjhjUKaQZeXl+9RlVBC1bdYLJJisdjs9/u/U8iHGP+pUCgEWrdAiQeDwY9t8ke0nVwLykPzHfpoPp9zf4W2ow6Dao45BAy4Hg6H313Cr8hNsA4iLWU96gTVH5REFSj/MhqNhrQF5+fnAtU7lIkmr/QGKB/Ozs4S6Pppky84CIYQfFDkZrNZYzqdtoUQCe0RmKcK/U1FGEaXbJ+/aBOG4K3WLuFTfNs3QQYItUGsrHU1bOPWSEZRxFaVuA0FrV6v94sOCOm5DrfZIWEYBuaYtYUzmUw4RkiS7Dp0LxcVxkUYE9AWcMiwrg0rOUZZevT09PQ1/YtZN0lTudnHlp6cnNyx1XIMpQGPg2ExnFAxQwcGPG7SUyRPgOBXRdAXLMfy5AlvkpioRhkA+bIt7jZhjSQs5U8h5Nazsmh4a6LsCGTc6XiQtRiPx4+mgDMQ2FozdvT9MyNC8zi1zafgXDg2AfYyNvWYsiGxeesQ+3AOE08xeXFxwVtLRC8HAnvqDYfBMibl6XFNLwxYA21U8ZMnQfRWyxtLshay6ChRRmCeLifN2nOA50h/h8IJ8edln6lA32YwsMaZiu1dRqxsQdBb0zKvte3J+8R5DniRhFcFGRnKDhBp7kU6vD2Je02DMgAh1CBPeJPkLJ1jlQOc/NCFgWU9xtPiiHaAnMh7sl2x0ZNqWzg08FXeqrbtXF8jKReHWL7EXdk39/OF/HGgLmPClmhYPYnYacom5373V1dXb+gAYAeA4J06RFyLyplPgtg9/T9d+ATgG51YEcbPgzTbCZOBsVXj0sYHA3tQ9XWgy/o3xLlwjo+PK8gd62jymR7Yrg2YNET1kbYABJd6XJctvjpzMuGSd5LsdrscGzeIGf5HU0V8RvpC4usFwqJDKcDXWcgnhif5H1CMuo11EFOOHDn2i79LWnDjgOZDWQAAAABJRU5ErkJggg=="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMLSURBVHgB7VixchoxEF2IZ2xXuRkXLnOd7SpXpgufAF06yBfE/gJIly7kCwxfYPIFvpTpSMEAlZUuDTPnCiiAvCWSI4QEJw7GLu7NaKTTaVdv91bS6ohy5MjhjUKaQZeXl+9RlVBC1bdYLJJisdjs9/u/U8iHGP+pUCgEWrdAiQeDwY9t8ke0nVwLykPzHfpoPp9zf4W2ow6Dao45BAy4Hg6H313Cr8hNsA4iLWU96gTVH5REFSj/MhqNhrQF5+fnAtU7lIkmr/QGKB/Ozs4S6Pppky84CIYQfFDkZrNZYzqdtoUQCe0RmKcK/U1FGEaXbJ+/aBOG4K3WLuFTfNs3QQYItUGsrHU1bOPWSEZRxFaVuA0FrV6v94sOCOm5DrfZIWEYBuaYtYUzmUw4RkiS7Dp0LxcVxkUYE9AWcMiwrg0rOUZZevT09PQ1/YtZN0lTudnHlp6cnNyx1XIMpQGPg2ExnFAxQwcGPG7SUyRPgOBXRdAXLMfy5AlvkpioRhkA+bIt7jZhjSQs5U8h5Nazsmh4a6LsCGTc6XiQtRiPx4+mgDMQ2FozdvT9MyNC8zi1zafgXDg2AfYyNvWYsiGxeesQ+3AOE08xeXFxwVtLRC8HAnvqDYfBMibl6XFNLwxYA21U8ZMnQfRWyxtLshay6ChRRmCeLifN2nOA50h/h8IJ8edln6lA32YwsMaZiu1dRqxsQdBb0zKvte3J+8R5DniRhFcFGRnKDhBp7kU6vD2Je02DMgAh1CBPeJPkLJ1jlQOc/NCFgWU9xtPiiHaAnMh7sl2x0ZNqWzg08FXeqrbtXF8jKReHWL7EXdk39/OF/HGgLmPClmhYPYnYacom5373V1dXb+gAYAeA4J06RFyLyplPgtg9/T9d+ATgG51YEcbPgzTbCZOBsVXj0sYHA3tQ9XWgy/o3xLlwjo+PK8gd62jymR7Yrg2YNET1kbYABJd6XJctvjpzMuGSd5LsdrscGzeIGf5HU0V8RvpC4usFwqJDKcDXWcgnhif5H1CMuo11EFOOHDn2i79LWnDjgOZDWQAAAABJRU5ErkJggg=="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Conduct a detailed Feasibility Report for informed
                        decision-making. <br /> <br />
                        Develop a comprehensive Design Basis Report to establish
                        foundational design parameters. <br /> <br />
                        Manage Tender/RFP processes for efficient EPC selection.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAYAAADhXXHAAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARrSURBVHgB7Vm7UhsxFL3rAGOquGBoWSpeRUyXDrtLB+mSKuYLIF061l262F8AlOmcL8B8AU7BYBcMmxqKrcANOOfYUkZe70O7NqHhznhWbx1dXZ17JYu8yvOIIzMU13VLCwsLK4VCodTtds9lxjITsARZLBYPHMc5RLakioPBYNBA2cnV1dUfmYG8kSllY2PjYH5+vgVQH5AtGlVFlFUAeG9paSm4u7v7LVNKbs2ur6/v4OMRkFHcxq85HNhxjgC0rCuQ9vGpTWMemcHGgPSfnp4Oe73er1DbL2jnIenqMoA+YVke07AGa9il96+z4wQA2ej3+03f94O4vgB9FLJngvay2nPBphHtcnFx8cYEqibk5C7q3sb15SJlpNmSWc6x0L9N7YulJGo2wS47+B0aZcOTD3usmyDDDKF3AtS2l8eenRiQLj7HSXap2nB7a6FJPbbFj7bpyvgia3rb89izEwP0TE+UZpebm5u71Ko5aUg6qP8KrbWjKsP2zAVjru2ouSZ4Fpz4w9To4+Pje2jzZxAE/ajJbm9ve+DQJvr56FeW8S3/BpD7qPclRlB3vry83EXyk+pXmpub67M83DZKszeh7bOmG+4K2tJOgzSGoNCucTiPZNz+KT7mWpU0sDj5A91Bxu2JeQ+aOpUZCBlGRvZtsoSv5wTYCWyx1EVt4sfVtZintsmL1DztVHIKGWZtbe0CyYYBtA2GKHPOpL6JPAstcjs+YpCajFY9BI18C5MeQzsrYg/SxY8HdwhMFfsYq4o5qpeXl6mxg5VT4NbThuh1DNA1pnmaFfFHCuvUib/QB5c2zbEeHh6241giN1gDdJ2aMLeLBw/kfxHliVhmeD5NTSf39/erHCvtAIZlTjIKTQOffQCpA8QZ0q5iD9qzp8JC5ic8H8rrWTQ5NdgQ6FXTEynQ5FuzKe3SikUYa4T6jkkSdVnza7lcLoFXc0VklCjXHUVdiWAN0J4ZpMSJcr0t1aeatuUx16FYsEkHzNcJaoz8mhbOQZPWBwZcu8uDaR4+7kZSnzSnUBWDX5VTOMvCr2GhU+AY4NqW4dZ5+Ko0G8kDlsJtVPxaM0BXZMSvO5JRtra23tEpmHzLsJNOwYYlrJ2C0rK5clcyCiK4bZ2mFsm3iOiatv2tnQKpCoCtB06duFBoZHUKmTzYS0sUWF99K/KfxfB4flT9BFjt99kxa2SVV1SwcyxKQbz1RrWbAAvuo136TCuP0mHUJM8H8kgFOzVV7Iev/LFgO51OEIqsStopIG19x08TsMFe2CnI6AZciXPvkYGMEVmdYjBujw5SPJmRkA2MrFVEltkpaOHrYBZ75pZjwWPXoWd1CuqmMOqIVxWxsGfTLpHd0+UcK6tTyPOK6ErCSwzdqSrj4t4i3wi/zOQNwqd5n61oe9ZlBK2BmelpQWqZ+pk+6s1qbALYJWICL8t2x8nUz/R8fsfTER/rAnXFHj7Vqxvsd9xgP19fX8/kz5CZ/ltDoXnwi+tMJ2ug8iovJX8BiSbQRfeSCm8AAAAASUVORK5CYII="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAYAAADhXXHAAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARrSURBVHgB7Vm7UhsxFL3rAGOquGBoWSpeRUyXDrtLB+mSKuYLIF061l262F8AlOmcL8B8AU7BYBcMmxqKrcANOOfYUkZe70O7NqHhznhWbx1dXZ17JYu8yvOIIzMU13VLCwsLK4VCodTtds9lxjITsARZLBYPHMc5RLakioPBYNBA2cnV1dUfmYG8kSllY2PjYH5+vgVQH5AtGlVFlFUAeG9paSm4u7v7LVNKbs2ur6/v4OMRkFHcxq85HNhxjgC0rCuQ9vGpTWMemcHGgPSfnp4Oe73er1DbL2jnIenqMoA+YVke07AGa9il96+z4wQA2ej3+03f94O4vgB9FLJngvay2nPBphHtcnFx8cYEqibk5C7q3sb15SJlpNmSWc6x0L9N7YulJGo2wS47+B0aZcOTD3usmyDDDKF3AtS2l8eenRiQLj7HSXap2nB7a6FJPbbFj7bpyvgia3rb89izEwP0TE+UZpebm5u71Ko5aUg6qP8KrbWjKsP2zAVjru2ouSZ4Fpz4w9To4+Pje2jzZxAE/ajJbm9ve+DQJvr56FeW8S3/BpD7qPclRlB3vry83EXyk+pXmpub67M83DZKszeh7bOmG+4K2tJOgzSGoNCucTiPZNz+KT7mWpU0sDj5A91Bxu2JeQ+aOpUZCBlGRvZtsoSv5wTYCWyx1EVt4sfVtZintsmL1DztVHIKGWZtbe0CyYYBtA2GKHPOpL6JPAstcjs+YpCajFY9BI18C5MeQzsrYg/SxY8HdwhMFfsYq4o5qpeXl6mxg5VT4NbThuh1DNA1pnmaFfFHCuvUib/QB5c2zbEeHh6241giN1gDdJ2aMLeLBw/kfxHliVhmeD5NTSf39/erHCvtAIZlTjIKTQOffQCpA8QZ0q5iD9qzp8JC5ic8H8rrWTQ5NdgQ6FXTEynQ5FuzKe3SikUYa4T6jkkSdVnza7lcLoFXc0VklCjXHUVdiWAN0J4ZpMSJcr0t1aeatuUx16FYsEkHzNcJaoz8mhbOQZPWBwZcu8uDaR4+7kZSnzSnUBWDX5VTOMvCr2GhU+AY4NqW4dZ5+Ko0G8kDlsJtVPxaM0BXZMSvO5JRtra23tEpmHzLsJNOwYYlrJ2C0rK5clcyCiK4bZ2mFsm3iOiatv2tnQKpCoCtB06duFBoZHUKmTzYS0sUWF99K/KfxfB4flT9BFjt99kxa2SVV1SwcyxKQbz1RrWbAAvuo136TCuP0mHUJM8H8kgFOzVV7Iev/LFgO51OEIqsStopIG19x08TsMFe2CnI6AZciXPvkYGMEVmdYjBujw5SPJmRkA2MrFVEltkpaOHrYBZ75pZjwWPXoWd1CuqmMOqIVxWxsGfTLpHd0+UcK6tTyPOK6ErCSwzdqSrj4t4i3wi/zOQNwqd5n61oe9ZlBK2BmelpQWqZ+pk+6s1qbALYJWICL8t2x8nUz/R8fsfTER/rAnXFHj7Vqxvsd9xgP19fX8/kz5CZ/ltDoXnwi+tMJ2ug8iovJX8BiSbQRfeSCm8AAAAASUVORK5CYII="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Conduct meticulous reviews of engineering drawings and
                        project documents to ensure accuracy. <br /> <br />
                        Implement due diligence of plant/equipment systems for
                        quality assurance.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKTSURBVHgB7VhNctowGP1szLBgUd+gyoaBYYF7gnKE3qD0BvQEpSdobpBwgjYnqHsDdwW7qieIuwJmAPd9VKaOI//GJiHxmxG2pWfpIev7kYieO4bD4WgwGNz2+/2rLC5zUH6hvKWSMKkkdrvdO1xswzAmWVzmoAjcjqkkSgs9NRqhVcPIIigDmKCIWJNQ646CIHDT+gBvrHgSFxltQ52P9m/L5XKe2geli7zKYywV4XKxWHxMakwUCpGfIHJ2IBmGj3/uUQ1A3w76tvke1xlm9rOWp6t0HMfebDa36lGuVqs3UkqfagAmhJfQVx4WxcdYF7qxtMa0Xq9H4T06mdYlkoEZlOGXA+x2uy10vEyr3+/3f6hmYIzjRLRaLVvHafxo1TgboRZnQVgjLu7tIi8WfU85+wmM50caD4b1HVnZvfdMDDamgiIZYfaUl182e1LvCavT6czhjtjqRKRdFIlI7KgpfbDU9lhf1xQJsxxsoO/G8jyPRd6Js5H4ngtJ0SQEPuWM8mOO/tx4ZWP1VaMRWjUs/un1eiPTNI+uhlMveiTA6kcw5iBax77XQuV7CLumJwJM2GW8DhonFlUATrKpZli8V8FAv6OVmH4H/+xL3k6KOPQcmGL8n+EDdPjYoniHGY072PgaSYBL/yKIyME9RJjtdnuTxWOROodf+tOr5OKCToTGj1aN8xaKXPO4oYPVvaK6RUSCTSJHV9ntdiUuh50h55pI015TTRBC2Ejej04euaf2oCPXSQlQ60kJqZ1C4ZOSEJhJdvpTOgE4s4fID0nteU7zOBfQ7Y8E/Xf2bkY3Y3WVdP80j5+1WX0l4KWBGQ+4ZHFD3kNygsaPVo0XIdRFkWofngrmcPaEQJKZPZ09/gKLshKf+6JflQAAAABJRU5ErkJggg=="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKTSURBVHgB7VhNctowGP1szLBgUd+gyoaBYYF7gnKE3qD0BvQEpSdobpBwgjYnqHsDdwW7qieIuwJmAPd9VKaOI//GJiHxmxG2pWfpIev7kYieO4bD4WgwGNz2+/2rLC5zUH6hvKWSMKkkdrvdO1xswzAmWVzmoAjcjqkkSgs9NRqhVcPIIigDmKCIWJNQ646CIHDT+gBvrHgSFxltQ52P9m/L5XKe2geli7zKYywV4XKxWHxMakwUCpGfIHJ2IBmGj3/uUQ1A3w76tvke1xlm9rOWp6t0HMfebDa36lGuVqs3UkqfagAmhJfQVx4WxcdYF7qxtMa0Xq9H4T06mdYlkoEZlOGXA+x2uy10vEyr3+/3f6hmYIzjRLRaLVvHafxo1TgboRZnQVgjLu7tIi8WfU85+wmM50caD4b1HVnZvfdMDDamgiIZYfaUl182e1LvCavT6czhjtjqRKRdFIlI7KgpfbDU9lhf1xQJsxxsoO/G8jyPRd6Js5H4ngtJ0SQEPuWM8mOO/tx4ZWP1VaMRWjUs/un1eiPTNI+uhlMveiTA6kcw5iBax77XQuV7CLumJwJM2GW8DhonFlUATrKpZli8V8FAv6OVmH4H/+xL3k6KOPQcmGL8n+EDdPjYoniHGY072PgaSYBL/yKIyME9RJjtdnuTxWOROodf+tOr5OKCToTGj1aN8xaKXPO4oYPVvaK6RUSCTSJHV9ntdiUuh50h55pI015TTRBC2Ejej04euaf2oCPXSQlQ60kJqZ1C4ZOSEJhJdvpTOgE4s4fID0nteU7zOBfQ7Y8E/Xf2bkY3Y3WVdP80j5+1WX0l4KWBGQ+4ZHFD3kNygsaPVo0XIdRFkWofngrmcPaEQJKZPZ09/gKLshKf+6JflQAAAABJRU5ErkJggg=="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Complete Project Management, including document control
                        and expert deputation for on-site supervision. <br />{" "}
                        <br />
                        Perform Leander Engineering for enhanced system
                        efficiency. <br /> <br />
                        Conduct thorough inspections, including rebar
                        inspections and system competence assessments.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANgSURBVHgB7Vg7chpBEB0+Kn2idUm5l0ASkgKtTyCUObN0AqMTCJ9A+AS2MmdGJ7BuoHXoyDjRL/E6c6IqHFBAAPg9mMHDMguLBbO2ile19DCf3Tc90z09LcQCCywwE6TGNbqu66yurr7udDqFVCrldLvdGuTl7e3thbCMSKI7OzunEGU8TrgNhAOQP7q/v/8mLCFtqszn82cQ78UfkgEeH9qs8Q+km8lk/K2trX1hCSMa3dvb24e2qvJvAO2dYKl91b69vX2aTqc5iZ5mm83miyAIamLOyIQrNjY2PkDk5V8PJIeW9+Hh4cv6+jr36kvuW2j2J+vEnJE11BX4A21VQPKHadDd3d059nAZRQdkPb2Nextjj0zjHmOMQ0Q9z3NarZYjX1qdMJbtBTxuqL4YJq+AeoojTMa7ubl5I6bAkDFVq1V9r7kTxjrCTKYM4ZseZYxASRpsbIwYE2Z7BVGgoWCJcqZB+IiLj35nGf3K6PdWxIAc9wlFarzWaDRycQ1xxD3hw35vBnBBIP3O9DGIK/Uf/SoiJjChQGqccJaWlty4Y40OHwS/iv6sey4IoiL6rsqDayoKuezTaFMBEz0AWV+OP9Rd3ziYrJ4vOMbLqDUulSv6J5QyBvG3JB8D48nEJYJV5kCGFjywfmkMvtSENZJEdlyj9HcXDE5WVlYckA9EQsjG6QSSr2QxEAkhPamD3PwVPpubm55ICBOJ6sC57oiEMBXRJLEgOmskTfSMYSGe55M6WiNKX8zYQR2fBMoF0b/yBPAuH9knarw1osvLyzySS6HqQBVAuogb71UUWStEGXsimFE+uHcEI8R7Jo9phpKXss3D4XJqekesk2kGKErJGOJQb2BcAXGs4mBotgStnofj1LlrVJ5sLsskEdWP0ZgsOmtrawfhdhtLn9PIRN7DsOSD22673XbD7f+MH63X664qa3erAWwQ9VWB8W1Up2w2qy/3yDV97kSlsfgsc4+a0kDMzgh5iwAC0/XEitXTUKSjZ2alCgOroO5SZgg9pJBKWt8T0zus7FFo6LO+7HTu8KskWoHUSRajLnvWjInXGjp3por0enUP44EwLtVjy+H3IPcrl/Zkd3eXS84kRC1OEsIqUR3X19eTcltDeDrxKE6JX+J/AfNNMueUGH4DWfJu/1xlAJAAAAAASUVORK5CYII="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANgSURBVHgB7Vg7chpBEB0+Kn2idUm5l0ASkgKtTyCUObN0AqMTCJ9A+AS2MmdGJ7BuoHXoyDjRL/E6c6IqHFBAAPg9mMHDMguLBbO2ile19DCf3Tc90z09LcQCCywwE6TGNbqu66yurr7udDqFVCrldLvdGuTl7e3thbCMSKI7OzunEGU8TrgNhAOQP7q/v/8mLCFtqszn82cQ78UfkgEeH9qs8Q+km8lk/K2trX1hCSMa3dvb24e2qvJvAO2dYKl91b69vX2aTqc5iZ5mm83miyAIamLOyIQrNjY2PkDk5V8PJIeW9+Hh4cv6+jr36kvuW2j2J+vEnJE11BX4A21VQPKHadDd3d059nAZRQdkPb2Nextjj0zjHmOMQ0Q9z3NarZYjX1qdMJbtBTxuqL4YJq+AeoojTMa7ubl5I6bAkDFVq1V9r7kTxjrCTKYM4ZseZYxASRpsbIwYE2Z7BVGgoWCJcqZB+IiLj35nGf3K6PdWxIAc9wlFarzWaDRycQ1xxD3hw35vBnBBIP3O9DGIK/Uf/SoiJjChQGqccJaWlty4Y40OHwS/iv6sey4IoiL6rsqDayoKuezTaFMBEz0AWV+OP9Rd3ziYrJ4vOMbLqDUulSv6J5QyBvG3JB8D48nEJYJV5kCGFjywfmkMvtSENZJEdlyj9HcXDE5WVlYckA9EQsjG6QSSr2QxEAkhPamD3PwVPpubm55ICBOJ6sC57oiEMBXRJLEgOmskTfSMYSGe55M6WiNKX8zYQR2fBMoF0b/yBPAuH9knarw1osvLyzySS6HqQBVAuogb71UUWStEGXsimFE+uHcEI8R7Jo9phpKXss3D4XJqekesk2kGKErJGOJQb2BcAXGs4mBotgStnofj1LlrVJ5sLsskEdWP0ZgsOmtrawfhdhtLn9PIRN7DsOSD22673XbD7f+MH63X664qa3erAWwQ9VWB8W1Up2w2qy/3yDV97kSlsfgsc4+a0kDMzgh5iwAC0/XEitXTUKSjZ2alCgOroO5SZgg9pJBKWt8T0zus7FFo6LO+7HTu8KskWoHUSRajLnvWjInXGjp3por0enUP44EwLtVjy+H3IPcrl/Zkd3eXS84kRC1OEsIqUR3X19eTcltDeDrxKE6JX+J/AfNNMueUGH4DWfJu/1xlAJAAAAAASUVORK5CYII="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Facilitate IBR Approval for operational plants. <br />{" "}
                        <br />
                        Optimize plant performance through comprehensive Plant
                        Optimization strategies. <br /> <br />
                        Address energy consumption concerns through advanced
                        solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="howwas">
              <div className="how-cnt">
                <span className="deco2" />
                <h1 className="font-face-futur-BK howh1">
                  How was the problem tackled
                </h1>
                <ul className="font-face-futur-LT howp">
                  <li>
                    SIXD strategically addressed the multifaceted challenges in
                    solar PV plants with a comprehensive and forward-thinking
                    approach. <br /> <br />
                    Beginning with meticulous planning, feasibility assessments,
                    and expert bid management, the groundwork was established
                    for successful project execution. On-site supervision during
                    critical phases and thorough commissioning assistance
                    ensured a seamless integration of solar PV systems. <br />{" "}
                    <br />
                    The team prioritized precision through exhaustive document
                    reviews, ensuring compliance with industry standards. <br />{" "}
                    <br />
                    Leveraging advanced inspection techniques, including rebar
                    inspections and system competence assessments, further
                    guaranteed the accuracy and reliability of the solar
                    infrastructure.
                  </li>
                </ul>
              </div>
              <div className="how-img">
                <img src="/assets/t12-13c7a402.png" />
              </div>
            </div>
            <div className="page">
              <img src="/assets/p12-e4fa40fd.png" className="page" />
            </div>
            <div className="arch">
              <div className="fxm">
                <span className="deco3" />
                <h1 className="font-face-futur-md-bt">
                  The <span className="orange">Difference</span> Achieved
                </h1>
              </div>
              <div className="side-data">
                <div className="side-image">
                  <img src="/assets/i1-a28f622a.gif" alt="Animated Frame" />
                </div>
                <div className="side-col">
                  <div className="row-side">
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">
                        Accuracy Improvement
                      </h1>
                      <p className="font-face-futur-LT">
                        Achieved precise system competence through detailed
                        reviews and inspections.
                      </p>
                    </div>
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">Cost Saving</h1>
                      <p className="font-face-futur-LT">
                        Streamlined project execution resulted in significant
                        cost savings.
                      </p>
                    </div>
                  </div>
                  <div className="row-side">
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">Energy Efficiencye</h1>
                      <p className="font-face-futur-LT">
                        Implemented plant optimization strategies for enhanced
                        energy efficiency.
                      </p>
                    </div>
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">
                        Regulatory Compliance
                      </h1>
                      <p className="font-face-futur-LT">
                        Facilitated IBR Approval, ensuring adherence to
                        regulatory standards.
                      </p>
                    </div>
                  </div>
                  <p className="p-side font-face-futur-LT">
                    In conclusion, our holistic approach successfully addressed
                    the intricate challenges faced by Solar PV Plants and
                    yielded significant improvements in accuracy,
                    cost-effectiveness, energy efficiency, and regulatory
                    compliance.
                  </p>
                </div>
              </div>
            </div>
            <div className="sharemy">
              <div className="sharex">
                <h1 className="font-face-futur-md-bt">Share this Case Study</h1>
                <div className="shareb">
                  <div className="share-50">
                    <div className="shareby font-face-futur-md-bt">
                      Share via
                    </div>
                    <ShareIcons />
                  </div>
                </div>
              </div>
            </div>
            <div className="innnercards">
              <h1 className="font-face-futur-BK">View more</h1>
              <div className="wrappermax">
                <Link to="/inner/10">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      Contributing to Efficient Power Generation with Hydropower{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Power</p>
                      <p>Dimension Control - DPM</p>
                    </div>
                    <img src="/assets/id6c1-2b51febb.png" />
                  </div>
                </Link>
                <Link to="/inner/17">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      End-to-End Owner’s Engineering Solutions Form Power Plant
                      Excellence{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Power</p>
                      <p>Owner’s Engineering</p>
                    </div>
                    <img src="/assets/id4c2-972761eb.png" />
                  </div>
                </Link>
                <Link to="/inner/4">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      Streamlining Project Management for Green Hydrogen Plants{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Power</p>
                      <p>Owner’s Engineering</p>
                    </div>
                    <img src="/assets/id3c3-775b0b1f.png" />
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </section>
      <SliderMe />
    </>
  );
};

export default Inner18;
