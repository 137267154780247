import React from "react";
import SliderMe from "../components/SliderMe";
import ShareIcons from "../components/ShareIcons";
import { Link } from "react-router-dom";

const Inner8 = () => {
  return (
    <>
      <section className="CaseInnerWrapper">
        <div className="CaseInnerContainer">
          <section className="InnerHero">
            <img
              src="/assets/15-2479152f.png"
              alt="Periodical Health Assessment of"
              style={{ width: "100%" }}
            />
            <h2 className="InnerHeroH1 font-face-futur-md-bt">
              Periodical Health Assessment of{" "}
              <span className="orange"> Storage Tanks.</span>{" "}
            </h2>
            <div className="tagsD font-face-futur-HV" id="topGap">
            <Link to="/sectors" className="tags2">Others</Link>
            <Link to="/services/dimension-control" className="tags2">Dimension Control</Link>
            </div>
            <div className="bar">
              <h2 className="InnerHeroH2 font-face-futur-md-bt">
                <div className="lineabc" />
                The Problem at Hand
              </h2>
              <p className="font-face-futur-BK innerPara">
                The challenge involved conducting geometrical measurements and
                inspections on diverse storage tanks, including external
                floating and fixed roof tanks, to assess dimensional compliance
                with API 653 standards.
              </p>
            </div>
            <div className="why font-face-futur-md-bt">
              <h1>
                Why <span>SIXD?</span>
              </h1>
              <p className="whyPara font-face-futur-LT">
                Equipped with intrinsic and flame proof-certified systems,
                experts with international exposure work in offshore conditions
                and have experience with complete offshore platforms as built
                through survey and modelling with required deliveries
                internationally.
              </p>
            </div>
            <div className="section-inner">
              <div className="lineabc2" />
              <h1 className="section-heading-inner font-face-futur-BK">
                Scope of Work
              </h1>
              <div className="card-container-inner">
                <p className="font-face-futur-LT" />
                <div id="topGap2">
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMLSURBVHgB7VixchoxEF2IZ2xXuRkXLnOd7SpXpgufAF06yBfE/gJIly7kCwxfYPIFvpTpSMEAlZUuDTPnCiiAvCWSI4QEJw7GLu7NaKTTaVdv91bS6ohy5MjhjUKaQZeXl+9RlVBC1bdYLJJisdjs9/u/U8iHGP+pUCgEWrdAiQeDwY9t8ke0nVwLykPzHfpoPp9zf4W2ow6Dao45BAy4Hg6H313Cr8hNsA4iLWU96gTVH5REFSj/MhqNhrQF5+fnAtU7lIkmr/QGKB/Ozs4S6Pppky84CIYQfFDkZrNZYzqdtoUQCe0RmKcK/U1FGEaXbJ+/aBOG4K3WLuFTfNs3QQYItUGsrHU1bOPWSEZRxFaVuA0FrV6v94sOCOm5DrfZIWEYBuaYtYUzmUw4RkiS7Dp0LxcVxkUYE9AWcMiwrg0rOUZZevT09PQ1/YtZN0lTudnHlp6cnNyx1XIMpQGPg2ExnFAxQwcGPG7SUyRPgOBXRdAXLMfy5AlvkpioRhkA+bIt7jZhjSQs5U8h5Nazsmh4a6LsCGTc6XiQtRiPx4+mgDMQ2FozdvT9MyNC8zi1zafgXDg2AfYyNvWYsiGxeesQ+3AOE08xeXFxwVtLRC8HAnvqDYfBMibl6XFNLwxYA21U8ZMnQfRWyxtLshay6ChRRmCeLifN2nOA50h/h8IJ8edln6lA32YwsMaZiu1dRqxsQdBb0zKvte3J+8R5DniRhFcFGRnKDhBp7kU6vD2Je02DMgAh1CBPeJPkLJ1jlQOc/NCFgWU9xtPiiHaAnMh7sl2x0ZNqWzg08FXeqrbtXF8jKReHWL7EXdk39/OF/HGgLmPClmhYPYnYacom5373V1dXb+gAYAeA4J06RFyLyplPgtg9/T9d+ATgG51YEcbPgzTbCZOBsVXj0sYHA3tQ9XWgy/o3xLlwjo+PK8gd62jymR7Yrg2YNET1kbYABJd6XJctvjpzMuGSd5LsdrscGzeIGf5HU0V8RvpC4usFwqJDKcDXWcgnhif5H1CMuo11EFOOHDn2i79LWnDjgOZDWQAAAABJRU5ErkJggg=="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMLSURBVHgB7VixchoxEF2IZ2xXuRkXLnOd7SpXpgufAF06yBfE/gJIly7kCwxfYPIFvpTpSMEAlZUuDTPnCiiAvCWSI4QEJw7GLu7NaKTTaVdv91bS6ohy5MjhjUKaQZeXl+9RlVBC1bdYLJJisdjs9/u/U8iHGP+pUCgEWrdAiQeDwY9t8ke0nVwLykPzHfpoPp9zf4W2ow6Dao45BAy4Hg6H313Cr8hNsA4iLWU96gTVH5REFSj/MhqNhrQF5+fnAtU7lIkmr/QGKB/Ozs4S6Pppky84CIYQfFDkZrNZYzqdtoUQCe0RmKcK/U1FGEaXbJ+/aBOG4K3WLuFTfNs3QQYItUGsrHU1bOPWSEZRxFaVuA0FrV6v94sOCOm5DrfZIWEYBuaYtYUzmUw4RkiS7Dp0LxcVxkUYE9AWcMiwrg0rOUZZevT09PQ1/YtZN0lTudnHlp6cnNyx1XIMpQGPg2ExnFAxQwcGPG7SUyRPgOBXRdAXLMfy5AlvkpioRhkA+bIt7jZhjSQs5U8h5Nazsmh4a6LsCGTc6XiQtRiPx4+mgDMQ2FozdvT9MyNC8zi1zafgXDg2AfYyNvWYsiGxeesQ+3AOE08xeXFxwVtLRC8HAnvqDYfBMibl6XFNLwxYA21U8ZMnQfRWyxtLshay6ChRRmCeLifN2nOA50h/h8IJ8edln6lA32YwsMaZiu1dRqxsQdBb0zKvte3J+8R5DniRhFcFGRnKDhBp7kU6vD2Je02DMgAh1CBPeJPkLJ1jlQOc/NCFgWU9xtPiiHaAnMh7sl2x0ZNqWzg08FXeqrbtXF8jKReHWL7EXdk39/OF/HGgLmPClmhYPYnYacom5373V1dXb+gAYAeA4J06RFyLyplPgtg9/T9d+ATgG51YEcbPgzTbCZOBsVXj0sYHA3tQ9XWgy/o3xLlwjo+PK8gd62jymR7Yrg2YNET1kbYABJd6XJctvjpzMuGSd5LsdrscGzeIGf5HU0V8RvpC4usFwqJDKcDXWcgnhif5H1CMuo11EFOOHDn2i79LWnDjgOZDWQAAAABJRU5ErkJggg=="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Conduct precise 3D measurements, alignment checks, and
                        accurate positioning for equipment, machineries, and
                        assemblies.
                        <br /> <br />
                        Provide expert assistance in levelling and installation
                        processes to ensure optimal functionality.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAYAAADhXXHAAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARrSURBVHgB7Vm7UhsxFL3rAGOquGBoWSpeRUyXDrtLB+mSKuYLIF061l262F8AlOmcL8B8AU7BYBcMmxqKrcANOOfYUkZe70O7NqHhznhWbx1dXZ17JYu8yvOIIzMU13VLCwsLK4VCodTtds9lxjITsARZLBYPHMc5RLakioPBYNBA2cnV1dUfmYG8kSllY2PjYH5+vgVQH5AtGlVFlFUAeG9paSm4u7v7LVNKbs2ur6/v4OMRkFHcxq85HNhxjgC0rCuQ9vGpTWMemcHGgPSfnp4Oe73er1DbL2jnIenqMoA+YVke07AGa9il96+z4wQA2ej3+03f94O4vgB9FLJngvay2nPBphHtcnFx8cYEqibk5C7q3sb15SJlpNmSWc6x0L9N7YulJGo2wS47+B0aZcOTD3usmyDDDKF3AtS2l8eenRiQLj7HSXap2nB7a6FJPbbFj7bpyvgia3rb89izEwP0TE+UZpebm5u71Ko5aUg6qP8KrbWjKsP2zAVjru2ouSZ4Fpz4w9To4+Pje2jzZxAE/ajJbm9ve+DQJvr56FeW8S3/BpD7qPclRlB3vry83EXyk+pXmpub67M83DZKszeh7bOmG+4K2tJOgzSGoNCucTiPZNz+KT7mWpU0sDj5A91Bxu2JeQ+aOpUZCBlGRvZtsoSv5wTYCWyx1EVt4sfVtZintsmL1DztVHIKGWZtbe0CyYYBtA2GKHPOpL6JPAstcjs+YpCajFY9BI18C5MeQzsrYg/SxY8HdwhMFfsYq4o5qpeXl6mxg5VT4NbThuh1DNA1pnmaFfFHCuvUib/QB5c2zbEeHh6241giN1gDdJ2aMLeLBw/kfxHliVhmeD5NTSf39/erHCvtAIZlTjIKTQOffQCpA8QZ0q5iD9qzp8JC5ic8H8rrWTQ5NdgQ6FXTEynQ5FuzKe3SikUYa4T6jkkSdVnza7lcLoFXc0VklCjXHUVdiWAN0J4ZpMSJcr0t1aeatuUx16FYsEkHzNcJaoz8mhbOQZPWBwZcu8uDaR4+7kZSnzSnUBWDX5VTOMvCr2GhU+AY4NqW4dZ5+Ko0G8kDlsJtVPxaM0BXZMSvO5JRtra23tEpmHzLsJNOwYYlrJ2C0rK5clcyCiK4bZ2mFsm3iOiatv2tnQKpCoCtB06duFBoZHUKmTzYS0sUWF99K/KfxfB4flT9BFjt99kxa2SVV1SwcyxKQbz1RrWbAAvuo136TCuP0mHUJM8H8kgFOzVV7Iev/LFgO51OEIqsStopIG19x08TsMFe2CnI6AZciXPvkYGMEVmdYjBujw5SPJmRkA2MrFVEltkpaOHrYBZ75pZjwWPXoWd1CuqmMOqIVxWxsGfTLpHd0+UcK6tTyPOK6ErCSwzdqSrj4t4i3wi/zOQNwqd5n61oe9ZlBK2BmelpQWqZ+pk+6s1qbALYJWICL8t2x8nUz/R8fsfTER/rAnXFHj7Vqxvsd9xgP19fX8/kz5CZ/ltDoXnwi+tMJ2ug8iovJX8BiSbQRfeSCm8AAAAASUVORK5CYII="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAYAAADhXXHAAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARrSURBVHgB7Vm7UhsxFL3rAGOquGBoWSpeRUyXDrtLB+mSKuYLIF061l262F8AlOmcL8B8AU7BYBcMmxqKrcANOOfYUkZe70O7NqHhznhWbx1dXZ17JYu8yvOIIzMU13VLCwsLK4VCodTtds9lxjITsARZLBYPHMc5RLakioPBYNBA2cnV1dUfmYG8kSllY2PjYH5+vgVQH5AtGlVFlFUAeG9paSm4u7v7LVNKbs2ur6/v4OMRkFHcxq85HNhxjgC0rCuQ9vGpTWMemcHGgPSfnp4Oe73er1DbL2jnIenqMoA+YVke07AGa9il96+z4wQA2ej3+03f94O4vgB9FLJngvay2nPBphHtcnFx8cYEqibk5C7q3sb15SJlpNmSWc6x0L9N7YulJGo2wS47+B0aZcOTD3usmyDDDKF3AtS2l8eenRiQLj7HSXap2nB7a6FJPbbFj7bpyvgia3rb89izEwP0TE+UZpebm5u71Ko5aUg6qP8KrbWjKsP2zAVjru2ouSZ4Fpz4w9To4+Pje2jzZxAE/ajJbm9ve+DQJvr56FeW8S3/BpD7qPclRlB3vry83EXyk+pXmpub67M83DZKszeh7bOmG+4K2tJOgzSGoNCucTiPZNz+KT7mWpU0sDj5A91Bxu2JeQ+aOpUZCBlGRvZtsoSv5wTYCWyx1EVt4sfVtZintsmL1DztVHIKGWZtbe0CyYYBtA2GKHPOpL6JPAstcjs+YpCajFY9BI18C5MeQzsrYg/SxY8HdwhMFfsYq4o5qpeXl6mxg5VT4NbThuh1DNA1pnmaFfFHCuvUib/QB5c2zbEeHh6241giN1gDdJ2aMLeLBw/kfxHliVhmeD5NTSf39/erHCvtAIZlTjIKTQOffQCpA8QZ0q5iD9qzp8JC5ic8H8rrWTQ5NdgQ6FXTEynQ5FuzKe3SikUYa4T6jkkSdVnza7lcLoFXc0VklCjXHUVdiWAN0J4ZpMSJcr0t1aeatuUx16FYsEkHzNcJaoz8mhbOQZPWBwZcu8uDaR4+7kZSnzSnUBWDX5VTOMvCr2GhU+AY4NqW4dZ5+Ko0G8kDlsJtVPxaM0BXZMSvO5JRtra23tEpmHzLsJNOwYYlrJ2C0rK5clcyCiK4bZ2mFsm3iOiatv2tnQKpCoCtB06duFBoZHUKmTzYS0sUWF99K/KfxfB4flT9BFjt99kxa2SVV1SwcyxKQbz1RrWbAAvuo136TCuP0mHUJM8H8kgFOzVV7Iev/LFgO51OEIqsStopIG19x08TsMFe2CnI6AZciXPvkYGMEVmdYjBujw5SPJmRkA2MrFVEltkpaOHrYBZ75pZjwWPXoWd1CuqmMOqIVxWxsGfTLpHd0+UcK6tTyPOK6ErCSwzdqSrj4t4i3wi/zOQNwqd5n61oe9ZlBK2BmelpQWqZ+pk+6s1qbALYJWICL8t2x8nUz/R8fsfTER/rAnXFHj7Vqxvsd9xgP19fX8/kz5CZ/ltDoXnwi+tMJ2ug8iovJX8BiSbQRfeSCm8AAAAASUVORK5CYII="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Perform thorough dimensional inspections covering
                        out-of-roundness, verticality, base settlement, and
                        distortions.
                        <br /> <br />
                        Conduct roof analysis and critical section thickness
                        analysis to assess structural integrity.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKTSURBVHgB7VhNctowGP1szLBgUd+gyoaBYYF7gnKE3qD0BvQEpSdobpBwgjYnqHsDdwW7qieIuwJmAPd9VKaOI//GJiHxmxG2pWfpIev7kYieO4bD4WgwGNz2+/2rLC5zUH6hvKWSMKkkdrvdO1xswzAmWVzmoAjcjqkkSgs9NRqhVcPIIigDmKCIWJNQ646CIHDT+gBvrHgSFxltQ52P9m/L5XKe2geli7zKYywV4XKxWHxMakwUCpGfIHJ2IBmGj3/uUQ1A3w76tvke1xlm9rOWp6t0HMfebDa36lGuVqs3UkqfagAmhJfQVx4WxcdYF7qxtMa0Xq9H4T06mdYlkoEZlOGXA+x2uy10vEyr3+/3f6hmYIzjRLRaLVvHafxo1TgboRZnQVgjLu7tIi8WfU85+wmM50caD4b1HVnZvfdMDDamgiIZYfaUl182e1LvCavT6czhjtjqRKRdFIlI7KgpfbDU9lhf1xQJsxxsoO/G8jyPRd6Js5H4ngtJ0SQEPuWM8mOO/tx4ZWP1VaMRWjUs/un1eiPTNI+uhlMveiTA6kcw5iBax77XQuV7CLumJwJM2GW8DhonFlUATrKpZli8V8FAv6OVmH4H/+xL3k6KOPQcmGL8n+EDdPjYoniHGY072PgaSYBL/yKIyME9RJjtdnuTxWOROodf+tOr5OKCToTGj1aN8xaKXPO4oYPVvaK6RUSCTSJHV9ntdiUuh50h55pI015TTRBC2Ejej04euaf2oCPXSQlQ60kJqZ1C4ZOSEJhJdvpTOgE4s4fID0nteU7zOBfQ7Y8E/Xf2bkY3Y3WVdP80j5+1WX0l4KWBGQ+4ZHFD3kNygsaPVo0XIdRFkWofngrmcPaEQJKZPZ09/gKLshKf+6JflQAAAABJRU5ErkJggg=="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKTSURBVHgB7VhNctowGP1szLBgUd+gyoaBYYF7gnKE3qD0BvQEpSdobpBwgjYnqHsDdwW7qieIuwJmAPd9VKaOI//GJiHxmxG2pWfpIev7kYieO4bD4WgwGNz2+/2rLC5zUH6hvKWSMKkkdrvdO1xswzAmWVzmoAjcjqkkSgs9NRqhVcPIIigDmKCIWJNQ646CIHDT+gBvrHgSFxltQ52P9m/L5XKe2geli7zKYywV4XKxWHxMakwUCpGfIHJ2IBmGj3/uUQ1A3w76tvke1xlm9rOWp6t0HMfebDa36lGuVqs3UkqfagAmhJfQVx4WxcdYF7qxtMa0Xq9H4T06mdYlkoEZlOGXA+x2uy10vEyr3+/3f6hmYIzjRLRaLVvHafxo1TgboRZnQVgjLu7tIi8WfU85+wmM50caD4b1HVnZvfdMDDamgiIZYfaUl182e1LvCavT6czhjtjqRKRdFIlI7KgpfbDU9lhf1xQJsxxsoO/G8jyPRd6Js5H4ngtJ0SQEPuWM8mOO/tx4ZWP1VaMRWjUs/un1eiPTNI+uhlMveiTA6kcw5iBax77XQuV7CLumJwJM2GW8DhonFlUATrKpZli8V8FAv6OVmH4H/+xL3k6KOPQcmGL8n+EDdPjYoniHGY072PgaSYBL/yKIyME9RJjtdnuTxWOROodf+tOr5OKCToTGj1aN8xaKXPO4oYPVvaK6RUSCTSJHV9ntdiUuh50h55pI015TTRBC2Ejej04euaf2oCPXSQlQ60kJqZ1C4ZOSEJhJdvpTOgE4s4fID0nteU7zOBfQ7Y8E/Xf2bkY3Y3WVdP80j5+1WX0l4KWBGQ+4ZHFD3kNygsaPVo0XIdRFkWofngrmcPaEQJKZPZ09/gKLshKf+6JflQAAAABJRU5ErkJggg=="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Evaluate shell settlement, analyze tank floor plate
                        conditions, and inspect top plate edge settlement and
                        tilt.
                        <br /> <br />
                        Examine floating roof rim space and gap, conducting
                        structural analysis to identify potential issues.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="howwas">
              <div className="how-cnt">
                <span className="deco2" />
                <h1 className="font-face-futur-BK howh1">
                  How was the problem tackled
                </h1>
                <ul className="font-face-futur-LT howp">
                  <li>
                    Data evaluation was conducted in strict adherence to API
                    Standard 653, employing PTB Certified Mathematical
                    Algorithm-based Dimension analysis software.
                    <br /> <br />
                    This software facilitated a meticulous examination of
                    collected data, enabling the creation of comprehensive
                    deviation reports at various vertical and horizontal
                    sections.
                    <br /> <br />
                    The utilization of certified algorithms ensured precision in
                    the analysis, allowing for accurate assessments of
                    out-of-roundness, verticality, settlement, distortions, and
                    other critical factors.
                    <br /> <br />
                    This method not only aligned with industry best practices
                    but also provided a systematic and reliable means to
                    evaluate and address geometrical complexities in storage
                    tanks.
                  </li>
                </ul>
              </div>
              <div className="how-img">
                <img src="/assets/t15-a54ddf9e.png" />
              </div>
            </div>
            <div className="page">
              <img src="/assets/p15-652ac3e4.png" className="page" />
            </div>
            <div className="arch">
              <div className="fxm">
                <span className="deco3" />
                <h1 className="font-face-futur-md-bt">
                  The <span className="orange">Difference</span> Achieved
                </h1>
              </div>
              <div className="side-data">
                <div className="side-image">
                  <img src="/assets/i1-a28f622a.gif" alt="Animated Frame" />
                </div>
                <div className="side-col">
                  <div className="row-side">
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">Enhanced Accuracy</h1>
                      <p className="font-face-futur-LT">
                        Reliable dimensional analysis and data evaluation
                        according to API Standard 653, increased the overall
                        accuracy of the process.
                      </p>
                    </div>
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">Cost Saving</h1>
                      <p className="font-face-futur-LT">
                        Implementing Certified Dimension analysis software
                        minimized errors, leading to efficient inspection
                        processes and cost savings in terms of maintenance,
                        repair, and potential downtime.
                      </p>
                    </div>
                  </div>
                  <div className="row-side">
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">Precision</h1>
                      <p className="font-face-futur-LT">
                        The solution allowed precise examination of critical
                        factors like settlement, distortions, and thickness
                        analysis, contributing to informed decision-making and
                        improved structural integrity.
                      </p>
                    </div>
                    <div className="card-main-side">
                      {" "}
                      <h1 className="font-face-futur-BK" />
                      <p className="font-face-futur-LT" />
                    </div>
                  </div>
                  <p className="p-side font-face-futur-LT">
                    Our end-to-end solutions have not only effectively tackled
                    the complexities of Green Hydrogen projects but have also
                    led to significant advancements in precision, cost
                    optimization, environmental compliance, and seamless project
                    transition for ongoing operational success.
                  </p>
                </div>
              </div>
            </div>
            <div className="sharemy">
              <div className="sharex">
                <h1 className="font-face-futur-md-bt">Share this Case Study</h1>
                <div className="shareb">
                  <div className="share-50">
                    <div className="shareby font-face-futur-md-bt">
                      Share via
                    </div>
                    <ShareIcons />
                  </div>
                </div>
              </div>
            </div>
            <div className="innnercards">
              <h1 className="font-face-futur-BK">View more</h1>
              <div className="wrappermax">
                <Link to="/inner/13">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      Physical Parts to Manufacturing Blueprints for Essential
                      Spares and Components.{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Others</p>
                      <p>Re-Engineering &amp; Supply</p>
                    </div>
                    <img src="/assets/id8c1-76b4db6d.png" />
                  </div>
                </Link>
                <Link to="/inner/1">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      Alignment Inspection and Structural Integrity Solutions
                      for Overhead Cranes{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Others</p>
                      <p>Dimension Control</p>
                    </div>
                    <img src="/assets/id3c2-dadc1ecf.png" />
                  </div>
                </Link>
                <Link to="/inner/15">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      Enhancing Passenger Comfort with Wipro Limited{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Others</p>
                      <p>Re-engineering &amp; Supplyy</p>
                    </div>
                    <img src="/assets/id1c3-1961532f.png" />
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </section>
      <SliderMe />
    </>
  );
};

export default Inner8;
